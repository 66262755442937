import {EagleApiInterface, EagleModuleApiInterface} from 'kernel/api/api'
import qs from 'qs'
export default (api: EagleApiInterface) => {
  api.collection.gaReportApi = <EagleModuleApiInterface>{
    pageview: (params: AnyObject) => api.request({
      url: `/api/admin/ga-report/pageview`,
      type: 'get',
      params,
    }),
    users: (params: AnyObject) => api.request({
      url: `/api/admin/ga-report/users`,
      type: 'get',
      params,
    }),
  }

  api.collection.baseApi = <EagleModuleApiInterface>{
    application: () => api.request({
      url: `/api/admin/application`,
      type: 'get',
    }),
    storageInfo: () => api.request({
      url: `/api/admin/storage`,
      type: 'get',
    }),
    mailTemplates: () => api.request({
      url: `/api/admin/mail/templates`,
      type: 'get',
    }),
    fcmPush: (params: AnyObject) => api.request({
      url: `/api/fcm`,
      type: 'post',
      params,
    }),
    sitemap: () => api.request({
      url: `/api/sitemap`,
      type: 'get',
    }),
    appConfigRead: (id: string) => api.request({
      url: `/api/admin/app-config/${id}`,
      type: 'get',
    }),
    appConfigUpdate: (id: string, params: AnyObject) => api.request({
      url: `/api/admin/app-config/${id}/update`,
      type: 'put',
      params,
    }),
  }

  api.collection.photoApi = <EagleModuleApiInterface>{
    upload: (formData: FormData, onProgress = null, params: {[key:string]: any}) => {
      const uploadParams = {
        ...params,
      }
      const query = window.eagleLodash.isEmpty(uploadParams)
        ? ''
        : `?${qs.stringify(uploadParams, { arrayFormat: 'indices' })}`

      return api.request({
        url: `/api/admin/photo/upload${query}`,
        type: 'post',
        data: formData,
        martipart: true,
        onUploadProgress: onProgress,
      })
    },
    read: (id: string) => api.request({
      url: `/api/admin/photo/${id}`,
      type: 'get',
    }),
    index: (params = {}) => api.request({
      url: `/api/admin/photo/index`,
      type: 'get',
      params,
    }),
    delete: (id: string) => api.request({
      url: `/api/admin/photo/${id}`,
      type: 'delete',
    }),
    batchDelete: (targets: string[]) => api.request({
      url: `/api/admin/photo/batch`,
      type: 'delete',
      params: { ids: targets },
    }),
  }

  api.collection.fileApi = <EagleModuleApiInterface>{
    download: (params: AnyObject) => api.request({
      url: `/api/admin/file/download`,
      type: 'post',
      responseType: 'blob',
      params,
    }),
    upload: (type: string, formData: FormData, onProgress = null) => {
      const params = {
        type,
      }
      const query = window.eagleLodash.isEmpty(params)
        ? ''
        : `?${qs.stringify(params, { arrayFormat: 'indices' })}`

      return api.request({
        url: `/api/admin/file/upload${query}`,
        type: 'post',
        data: formData,
        martipart: true,
        onUploadProgress: onProgress,
      })
    },
    read: (id: string) => api.request({
      url: `/api/admin/file/${id}`,
      type: 'get',
    }),
    index: (params = {}) => api.request({
      url: `/api/admin/file/index`,
      type: 'get',
      params,
    }),
    delete: (id: string) => api.request({
      url: `/api/admin/file/${id}`,
      type: 'delete',
    }),
    batchDelete: (targets: string[]) => api.request({
      url: `/api/admin/file/batch`,
      type: 'delete',
      params: { ids: targets },
    }),
  }

  api.collection.otpApi = <EagleModuleApiInterface>{
    index: (params = {}) => api.request({
      url: `/api/admin/otp/index`,
      type: 'get',
      params,
    }),
    delete: (id: string) => api.request({
      url: `/api/admin/otp/${id}`,
      type: 'delete',
    }),
    batchDelete: (targets: string[]) => api.request({
      url: `/api/admin/otp/batch`,
      type: 'delete',
      params: { ids: targets },
    }),
  }
}
